import "../Polyfills/arrayAtPolyfill";
import { ProviderStatus } from "./providerLib";

export const ChartViews = Object.freeze({
  incentiveSpirometry: 'incentiveSpirometry',
  pulmonaryFunctionTesting: 'pulmonaryFunctionTesting',
  realTimeSession: 'realTimeSession',
  patientSettings: 'patientSettings',
  participantSettings: 'participantSettings',
  trialResearch: 'trialResearch'
});

export const ONE_MINUTE_IN_MILLISECONDS = 60000;

export const ONE_DAY_IN_MILLISECONDS = 86400000;

export const ONE_YEAR_IN_MILLISECONDS = 31557600000; // ADDS AN EXTRA .25 DAY TO ACCOUNT FOR LEAP YEARS

export const DefaultChartView = ChartViews.pulmonaryFunctionTesting;

export const CHIME_APP_VERSION = "1.11.0";

export const KINESIS_APP_VERSION = "1.12.0";

export const EFFORT_MARKER_FIX_APP_VERSION = "1.13.0";

export const defaultScheduledRtcMessage = `Appointment reminder for your upcoming Pulmonary Function Test.`;

export const ACCEPTABLE = 'Acceptable';

export const USABLE = 'NotAcceptableAndUsable';

export const NOT_ACCEPTABLE = 'NotAcceptableAndNotUsable';

export const ALTERNATIVE_COACHING_OPTIONS = ['na', 'in person', 'phone', 'other video'];

export const FACE_MASK_OPTIONS = ['no face mask', 'face mask'];

export const PATIENT_PFT_ACCESSORIES = [{ value: 'No Accessories', label: 'No Accessories' }, { value: 'Face Mask', label: 'Face Mask' }, { value: 'Trach', label: 'Trach' }, { value: 'Nose Clip', label: 'Nose Clip' }];

export const PATIENT_POSITIONS = ['na', 'standing', 'seated', 'supine'];

export const MED_USE_FREQUENCIES = ['onceDaily', 'twiceDaily'];

export const NOTIFICATION_CHAR_LIMIT = 150;

export const MINIMUM_UNACCEPTABLE_FEV1_GRADE = 'E';

export const MINIMUM_UNACCEPTABLE_FVC_GRADE = 'E';

export const AZ_PROGRAM = 'D6582C00001';

export const TRIAL_LANGUAGES = [
  { value: 'af', label: 'af' },
  { value: 'ar', label: 'ar' },
  { value: 'bg', label: 'bg' },
  { value: 'bn', label: 'bn' },
  { value: 'cs', label: 'cs' },
  { value: 'da', label: 'da' },
  { value: 'de', label: 'de' },
  { value: 'de-ch', label: 'de-ch' },
  { value: 'de-de', label: 'de-de' },
  { value: 'en-au', label: 'en-au' },
  { value: 'en-ca', label: 'en-ca' },
  { value: 'en-gb', label: 'en-gb' },
  { value: 'en-ie', label: 'en-ie' },
  { value: 'en-us', label: 'en-us' },
  { value: 'es-ar', label: 'es-ar' },
  { value: 'es-es', label: 'es-es' },
  { value: 'es-us', label: 'es-us' },
  { value: 'fr-fr', label: 'fr-fr' },
  { value: 'fr-be', label: 'fr-be' },
  { value: 'fr-ca', label: 'fr-ca' },
  { value: 'fr-ch', label: 'fr-ch' },
  { value: 'gu', label: 'gu' },
  { value: 'he', label: 'he' },
  { value: 'hi', label: 'hi' },
  { value: 'it-ch', label: 'it-ch' },
  { value: 'it-it', label: 'it-it' },
  { value: 'ja', label: 'ja' },
  { value: 'kn', label: 'kn' },
  { value: 'ko', label: 'ko' },
  { value: 'pam', label: 'pam' },
  { value: 'pt-br', label: 'pt-br' },
  { value: 'ml', label: 'ml' },
  { value: 'mr', label: 'mr' },
  { value: 'ms-my', label: 'ms-my' },
  { value: 'nl-be', label: 'nl-be' },
  { value: 'nl-nl', label: 'nl-nl' },
  { value: 'pl', label: 'pl' },
  { value: 'ro', label: 'ro' },
  { value: 'ru', label: 'ru' },
  { value: 'st', label: 'st' },
  { value: 'sv-se', label: 'sv-se' },
  { value: 'ta-in', label: 'ta-in' },
  { value: 'ta-my', label: 'ta-my' },
  { value: 'te', label: 'te' },
  { value: 'th', label: 'th' },
  { value: 'tl', label: 'tl' },
  { value: 'vi', label: 'vi' },
  { value: 'xh', label: 'xh' },
  { value: 'zh-cn', label: 'zh-cn' },
  { value: 'zh-tw', label: 'zh-tw' },
  { value: 'zu', label: 'zu' },
];

/** @todo Make these dynamic per [ZES-4](https://zephyrx.atlassian.net/browse/ZES-4) */
export const TRIAL_LABELS = [
  { value: 'V1', label: 'V1' },
  { value: 'V2', label: 'V2' },
  { value: 'V3a', label: 'V3a' },
  { value: 'V3b', label: 'V3b' },
  { value: 'V3c', label: 'V3c' },
  { value: 'V4', label: 'V4' },
  { value: 'V5', label: 'V5' },
  { value: 'V6a', label: 'V6a' },
  { value: 'V6b', label: 'V6b' },
  { value: 'V7', label: 'V7' },
  { value: 'pre-BD', label: 'pre-BD' },
  { value: 'post-BD', label: 'post-BD' },
  { value: 'pre-IMP', label: 'pre-IMP' },
  { value: 'post-IMP', label: 'post-IMP' },
];

export const ECOA_OPTIONS = [
  { value: 'acq6', label: 'ACQ6' },
  { value: 'caat', label: 'CAAT' },
  { value: 'snot22', label: 'SNOT-22' },
];

/** @type {readonly ['fvc', 'fev1', 'pef', 'vc']} */
export const TRIAL_BASELINES_PARAMETERS = ['fvc', 'fev1', 'pef', 'vc'];

export const ENVIRONMENT_URL = process.env.REACT_APP_STAGE === 'prod'
  ? 'https://5bah6y94na.execute-api.us-east-1.amazonaws.com/prod/environment/'
  : process.env.REACT_APP_STAGE === 'preview'
    ? 'https://6ibufobt62.execute-api.us-east-1.amazonaws.com/preview/environment/'
    : 'https://tu5rh29njd.execute-api.us-east-1.amazonaws.com/test/environment/';

export const ENVIRONMENT_URL_WEST = process.env.REACT_APP_STAGE === 'prod'
  ? 'https://pnsh61x2ji.execute-api.us-west-2.amazonaws.com/prod/environment/' // Prod Env US West Failover
  : process.env.REACT_APP_STAGE === 'preview'
    ? 'https://mqv7aqad1h.execute-api.us-west-2.amazonaws.com/preview/environment/' // Preview Env US West Failover
    : 'https://uwv1dy70il.execute-api.us-west-2.amazonaws.com/test/environment/'; // Test Env US West Failover

// export const zephyrxInternalBillingID = '165576c3-0453-4092-acb6-e5a12ce03341';
export const zephyrxInternalBillingID = process.env.REACT_APP_STAGE?.toLowerCase() === 'test' ? 'b567f557-5739-4ab4-b5c1-73bbaf35f149' : '29dfdec4-68ad-4c71-adf9-185ad1f664ed';

// this prevents the BNI orgs from seeing the overread rejection notifications / patient accepting
export const overreadingOrganizationWhitelist = ["a60105f2-72d4-4bbc-81c2-7879e518b37a", "fb6f58b7-c44f-4250-8887-d3804db94a26"];

export const ethnicityMap = Object.freeze({
  CAUCASIAN: "Caucasian",
  AFRICAN_AMERICAN: "Black",
  NORTH_EAST_ASIAN: "North East Asian",
  SOUTH_EAST_ASIAN: "South East Asian",
  OTHER: "Other"
});

export const programMap = Object.freeze({
  ADULT: "Adult",
  PEDIATRIC: "Pediatric",
  AFFILIATE: "Affiliate",
  PRECISE: "PrecISE",
  PROMISE: "PROMISE",
  LHC: "LHC",
  MATCH: "MATCH",
  HEALEY: "HEALEY",
  ALEXION: "Alexion",
  AEROBPD: "AeroBPD",
  ALECTOR: "Alector",
  APICBIO: "APICBIO",
  AURALANALYTICS: "AuralAnalytics",
  MANNKIND: "MannKind",
  MAYFLOWERS: "MAYFLOWERS",
  BETHISRAEL: "BethIsrael",
  NOVARTIS: "Novartis",
  MITSUBISHI: "Mitsubishi",
  HILLROM: "Hillrom",
  DRAFTSTUDY: "DRAFTStudy",
  "ASPIRE.ALLERGY": "Aspire.Allergy",
  ZEPHYRX: "ZEPHYRx"
});

export const qualityMap = Object.freeze({
  GoodBlow: 'Acceptable effort',
  DontHesitate: 'Patient hesitated',
  AbruptEnd: 'Effort ended abruptly',
  DontStartTooEarly: 'Effort was started too early',
  BlowOutLonger: 'Patient needed to exhale longer',
  BlowOutFaster: 'Patient needed to exhale faster',
  AvoidCoughing: 'Patient likely coughed during effort',
  InhaleExhaleDontMatch: 'Inspiratory and expiratory legs do not match',
  FailedBEV: 'Effort had a failing BEV',
});

export const momentjsMap = Object.freeze({
  "MM/DD/YYYY": "MM DD YYYY"
})

export const defaultOrganizationSecurityConfig = Object.freeze({
  needNumbers: true,
  needUpperCase: true,
  needLowerCase: true,
  needSpecialCharacter: false,
  passwordLength: 8,
  enablePasswordExpiration: false,
  passwordExpirationDays: 120,
  autoLogoutMinutes: 15,
  enableMfa: true,
  enableLockout: false,
  maxFailedLoginAttempts: 5,
  preventPasswordReuse: false,
  passwordReuseLimit: 3,
  useIpWhitelist: false,
  ipWhitelist: [],
  useEmailWhitelist: false,
  emailWhitelist: [],
})

export const defaultOrganizationRequiredFields = Object.freeze({
  name: '',
  streetAddress: '',
  streetAddress2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  phone: '',
  enableSeriesEditing: '',
  hide: '',
  enableCoachingBeta: '',
  enableSvc: '',
  enableOverread: '',
  enableOverreadReadOnly: '',
  overreadCoachedSeriesOnly: '',
  ignored: '',
  needSpecialCharacter: '',
  passwordLength: '',
  enablePasswordExpiration: '',
  passwordExpirationDays: '',
  autoLogoutMinutes: '',
  enableLockout: '',
  enableMfa: '',
  maxFailedLoginAttempts: '',
  preventPasswordReuse: '',
  passwordReuseLimit: '',
  useEmailWhitelist: '',
  useAlternateLogo: '',
  alternateLogoSrc: '',
  alternateLogoText: '',
  enableCustomSurvey: '',
  customSurvey: '',
  enablePreCoachingCustomSurvey: '',
  revocable: '',
  consentable: '',
  useStudyID: '',
  nameStudyID: '',
  reportingStudyID: '',
  enableDemographicsEditing: '',
  enableScheduleCoachingSession: '',
  birthdateDisplay: '',
  trialLanguages: '',
  config_CustomConsentLanguage: '',
  studyIDPrefix: '',
  studyIDSuffix: ''
});

export const defaultMobileConfig = {
  config_DisableDownloadSync: false,
  config_DisableUploadSync: false,
  config_DisableAutomaticSync: false,
  config_DisableV0Sync: false,
  config_ShowBreathingTests: true,
  config_ShowDataSharing: true,
  config_ShowEditDemographics: true,
  config_ShowFvc: true,
  config_ShowGames: true,
  config_ShowPastResults: true,
  config_ShowSvc: false,
  config_ShowPeakFlow: false,
  config_UseLegacySvc: false,
  config_TasksEnabled: false,
  config_EnablePushNotifications: true,
  config_ShowPefForFvc: false,
  config_ShowFev1ForPeakFlow: false,
  config_ShowPeakFlowInLMin: false,
  config_ShowCSVExport: false
};

export const alexionMobileConfig = {
  config_DisableDownloadSync: true,
  config_DisableUploadSync: false,
  config_DisableAutomaticSync: false,
  config_DisableV0Sync: true,
  config_ShowBreathingTests: true,
  config_ShowDataSharing: false,
  config_ShowEditDemographics: false,
  config_ShowFvc: false,
  config_ShowGames: false,
  config_ShowPastResults: false,
  config_ShowSvc: true,
  config_ShowPeakFlow: false,
  config_UseLegacySvc: false,
  config_TasksEnabled: false,
  config_EnablePushNotifications: false,
  config_ShowPefForFvc: false,
  config_ShowFev1ForPeakFlow: false,
  config_ShowPeakFlowInLMin: false,
  config_ShowCSVExport: false
};

export const defaultOrganizationFeatureSet = {
  consentable: true,
  revocable: true,
  hide: false,
  useStudyID: false,
  nameStudyID: false,
  reportingStudyID: false,
  enableScheduleCoachingSession: false,
  enableCoachingBeta: true,
  enableSeriesEditing: true,
  enableDemographicsEditing: true,
  defaultReminderMessage: '',
  enableSecondReminder: false,
  defaultScheduledRtcsMessage: defaultScheduledRtcMessage,
  trendNotificationTimeOfDay: 18,
  defaultTrendNotifications: [],
  useAWSKinesis: false,
  useAWSChime: true,
  enableOverread: false,
  enableOverreadReadOnly: false,
  overreadCoachedSeriesOnly: false,
  enableSurveys: false,
  surveyList: [],
  disableEthnicity: false,
  passwordSettings: { ...defaultOrganizationSecurityConfig },
  billing: { ignored: false },
  useAlternateLogo: false,
  birthdateDisplay: 'birthYearOnly',
  newActivityTime: 2,
  enableScheduleCoachingSessionCalendarInvites: false,
  exposeEffortReport: false,
  acceptIvc: false,
  hideRemovePatient: false,
  enablePatientSubscriptions: false,
  enableKioskDeepLink: false,
  studyIDPrefix: '',
  studyIDSuffix: '',
  spirometerTypes: {
    spirobank2: true,
    spirobankSmart: false
  }
};

export const defaultOrganizationConfig = Object.freeze({
  name: '',
  streetAddress: '',
  streetAddress2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
  phone: '',
  program: '',
  displayProgram: '',
  logo: '',
  config_CustomConsentLanguage: '',
  billingID: zephyrxInternalBillingID,
  featureSet: defaultOrganizationFeatureSet,
  status: ProviderStatus.active,
  ...defaultMobileConfig
});

export const frequencyMap = {
  never: "Never",
  daily: "Daily",
  weekly: "Weekly",
  biweekly: "Bi-Weekly",
  monthly: "Monthly",
  bimonthly: "Bi-Monthly",
  quarterly: "Quarterly",
};

export const fvcTrendNotificationOptions = ['FVC', 'FEV1', 'PEF'];

export const pefTrendNotificationOptions = ['FEV1', 'PEF'];

export const svcTrendNotificationOptions = ['VC'];

export const trendNotificationOptions = [...fvcTrendNotificationOptions, ...svcTrendNotificationOptions];

export const zephyrxOrganizationIDs = ['fbef360c-60cb-4428-921b-3762efd1c083', 'ddeeee9b-200a-4f16-af90-9eb5bda4ee8c', '55d20f1d-1a8c-4330-8754-fdc7fb2f5229', '8536b967-2d78-457d-a969-61e02a4df979', '88f8adf2-e445-4167-914c-caad31bcabf3', 'a2f4accc-5731-4df5-aaa5-e215ee64a56e', 'c622ef67-b193-49e0-842e-9a2269bc92d2'];

/**
 * @param {number} lowerBound
 * @param {number} upperBound
 * @param {number} value
 * @returns {number} value | lowerBound | upperBound
 */
export const clamp = (lowerBound, upperBound, value) => value >= upperBound ? upperBound : value <= lowerBound ? lowerBound : value;

export const formatPredicted = (predicted) => {
  return predicted && !isNaN(predicted) && predicted > 0 ? Math.round(predicted) + "%" : null
};

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function copyToClipBoard(elementID) {
  selectText(elementID);
  // copyText.select();
  // copyText.setSelectionRange(0, 99999); /* For mobile devices */
  document.execCommand("copy");
}

function selectText(containerid) {
  let range;
  if (document.selection) {
    range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select();
  } else if (window.getSelection) {
    range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function formDataJSON(formEl) {
  const formData = new FormData(formEl);
  const jsonObject = {};
  for (let [key, value] of formData.entries()) {
    jsonObject[key] = value;
  }
  return jsonObject;
}

export function scaleRange(originalStart, originalEnd, newStart, newEnd, value) {
  const scale = (newEnd - newStart) / (originalEnd - originalStart);
  return newStart + ((value - originalStart) * scale);
};

export function convertLitersToStdDev(predicted, lln, liters) {
  const originalStart = 0;
  const originalEnd = lln;
  const newStart = 0;
  const newEnd = 1.645;
  return scaleRange(originalStart, originalEnd, newStart, newEnd, liters) * (liters < predicted ? -1.0 : 1.0);
};

// const GetSimulatedBreathData = () => {
//   const bytes = Buffer.from(RawSimulatedBreathData, 'base64');
//   const text = bytes.toString('ascii');
//   var json;
//   try {
//     json = JSON.parse(text);
//   } catch (e) {
//     console.log(e);
//     return [];
//   }
//   if (!(json && json.points && json.points.length > 0 && json.points[0].timestamp)) {
//     return [];
//   }
//   return json.points;
// }

// export const SimulatedBreathData = GetSimulatedBreathData();

export const convertEffortToReport = effort => ({
  ...effort,
  fvc: effort.fvc / 100.0,
  pef: (effort.pef / 100.0 * 60),
  fet: (effort.exhaleEnd - effort.exhaleStart) / 1000.0
});

export const isValidReport = report =>
  report
  && report.flowVolumeCurve
  && report.fvc
  && report.pef
  && report.fet;

export function timeSince(date, translation) {
  if (date === 0) {
    return "Never"
  }
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return `${interval} ${translation.years || "years"}`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} ${translation.months || "months"}`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} ${translation.days || "days"}`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} ${translation.hours || "hours"}`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} ${translation.minutes || "minutes"}`;
  }
  return `${Math.floor(seconds)} ${translation.seconds || "seconds"}`;
}

export const getLatestStatsFromChart = chart => {
  var latestStats = null;
  var timestamp = null;

  // Get points without null values
  if (chart && chart.fvc && chart.fvc.chart && chart.fvc.chart.length > 0) {
    const chartPoints = chart.fvc.chart.filter(point => point.fvc);

    // Get the most recent timestamp
    if (chartPoints && chartPoints.length > 0 && chartPoints[chartPoints.length - 1]) {
      timestamp = chartPoints[chartPoints.length - 1].timestamp;

      // Get the stats for the most recent timestamp
      if (chart && chart.fvc && chart.fvc.stats && chart.fvc.stats[timestamp]) {
        latestStats = chart.fvc.stats[timestamp];
      }
    }
  }
  return {
    currentStats: latestStats,
    timestamp: timestamp
  };
};

// TODO: DEPRECATE THIS
export function tidyFlowVolume(spirometryResult, convertToXY = false) {
  if (!spirometryResult || !(spirometryResult.flowVolumeCurve)) return [];

  let backendFlowVolumeCurveData;
  try {
    backendFlowVolumeCurveData = JSON.parse(atob(spirometryResult.flowVolumeCurve));
  } catch (e) {
    console.log("Unable to parse backend flow volume curve data")
    console.log(e);
  };

  let containsVtPointsCurve = !!spirometryResult?.vtPointsCurve;
  const decodedVtPointsCurve = containsVtPointsCurve ? atob(spirometryResult.vtPointsCurve) : atob(spirometryResult.flowVolumeCurve);


  let fvPointsJson = backendFlowVolumeCurveData;

  // VOLUME TIME CURVE
  let vtPointsJson;
  try {
    vtPointsJson = JSON.parse(decodedVtPointsCurve);
    if (containsVtPointsCurve) { // Copy the MIR data to our conventionally named properties for vtPointsCurve
      vtPointsJson.points.forEach(point => {
        point.volume = point.volume_L
        point.timestamp = point.time_s
      });
    }
  } catch (e) {
    console.log(e);
    containsVtPointsCurve = false;
    try {
      vtPointsJson = backendFlowVolumeCurveData;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  // IF IT'S NOT A VALID fvPointsCurve USE OUR FALLBACK LOGIC
  if (fvPointsJson?.points?.length <= 0) return [];
  if (vtPointsJson?.points?.length <= 0) return [];

  let firstTimestamp = fvPointsJson.points[0]?.timestamp ?? backendFlowVolumeCurveData.points[0].timestamp;
  let exhalePoints = [{ flow: 0, volume: 0, timestamp: 0 }], inhalePoints = [], volumeTimePoints = [{ volume: 0, timestamp: 0 }]
  let minVol = 0, maxVol = -Infinity;
  let minFlow = 0, maxFlow = -Infinity;
  let endInhale = false, hitPef = false;

  fvPointsJson.points.forEach((point) => {
    let { flow, volume, timestamp } = point;
    if (hitPef && flow === 0) endInhale = true;

    if (flow > 10000 || volume > 100000) console.log("error in point data - please check this curve in DB");
    else {
      if (flow > 0 && volume >= exhalePoints[exhalePoints.length - 1].volume && inhalePoints.length === 0) {
        if (!endInhale) {
          if (spirometryResult.pef && ((flow / 100) > (spirometryResult.pef + 0.1))) flow = flow / 10;
          if (flow > maxFlow) maxFlow = flow;
          if (volume > maxVol) maxVol = volume;
          if (flow / 100 > spirometryResult.pef - 0.2) hitPef = true;
          exhalePoints.push({ flow: (flow / 100), volume: (volume / 1000), timestamp: timestamp - firstTimestamp });
        } else {
          exhalePoints.push({ flow: 0, volume: (point.volume / 1000), timestamp: point.timestamp - firstTimestamp });
        }
      }
      else if (flow < 0) { // INHALING - in Millilitres
        if (inhalePoints.length === 0 || (volume / 1000) <= inhalePoints[inhalePoints.length - 1].volume) {
          if (flow < minFlow) minFlow = flow;
          if (volume < minVol) minVol = volume;
          inhalePoints.push({ flow: (flow / 1000), volume: (volume / 1000), timestamp: timestamp - firstTimestamp });
        }
      };
    };
  });

  // APPEND A 0 FLOW POINT TO THE END OF THE INHALE - IF NO INHALE POINTS, USE LAST EXHALE POINTS
  inhalePoints.push({ flow: 0, volume: inhalePoints[inhalePoints.length - 1]?.volume ?? exhalePoints[exhalePoints.length - 1]?.volume, timestamp: inhalePoints[inhalePoints.length - 1]?.timestamp + 1 ?? exhalePoints[exhalePoints.length - 1]?.timestamp + 1 });

  // MAKE SURE FLOW VOLUME CURVE TOUCHES FVC VALUE
  if (spirometryResult.fvc > 0) {
    inhalePoints.unshift({ flow: 0, volume: spirometryResult.fvc, timestamp: exhalePoints[exhalePoints.length - 1].timestamp + 2 });
    exhalePoints.push({ flow: 0, volume: spirometryResult.fvc, timestamp: exhalePoints[exhalePoints.length - 1].timestamp + 1 });
  };

  // VOLUME TIME CURVE
  if (containsVtPointsCurve) {
    vtPointsJson.points.forEach(point => {
      const { volume, timestamp } = point;
      volumeTimePoints.push({ volume, timestamp });
    });
  } else { // IF IT'S NOT A VALID vtPointsCurve, USE THE fvPointsJson
    let firstTimestampWithVolumePresent = undefined;
    fvPointsJson.points.forEach(point => {
      const { volume, timestamp } = point;
      const shouldIgnorePoint = (volume === 0 && volumeTimePoints.at(-1).volume === 0) || (volume < volumeTimePoints.at(-1).volume * 1000) || (timestamp / 1000 > spirometryResult?.fet);
      if (shouldIgnorePoint) return;

      if (!firstTimestampWithVolumePresent) firstTimestampWithVolumePresent = timestamp;
      volumeTimePoints.push({
        volume: volume / 1000,
        timestamp: (timestamp - firstTimestampWithVolumePresent) / 1000
      });
    })
  };

  // MAKE SURE VOLUME TIME POINTS REACH THE FET
  if (spirometryResult?.fet && volumeTimePoints.at(-1).timestamp < spirometryResult.fet) {
    const { volume } = volumeTimePoints.at(-1);
    volumeTimePoints.push({ volume, timestamp: spirometryResult.fet });
  };

  // CREATE VOLUME TICKS
  let volTicks = [];

  for (let i = Math.ceil(minVol === 0 ? minVol : minVol / 1000) - 1; i <= Math.floor(maxVol / 1000) + 2; i += 1) {
    volTicks.push(i);
  };
  // TRIM DOWN THE LENGTH
  if (volTicks.length > 10) volTicks = volTicks.filter(tick => tick % 2 === 0);

  // CREATE FLOW TICKS
  let flowTicks = [];

  if (inhalePoints.length < 10) {
    for (let i = -6; i <= Math.floor(maxFlow / 100) + 3; i += 1) {
      if (i % 2 === 0) flowTicks.push(i);
    };
  } else {
    for (let i = Math.ceil(minFlow / 1000) - 2; i <= Math.floor(maxFlow / 100) + 3; i += 1) {
      if (i % 2 === 0) flowTicks.push(i);
    };
  };
  // TRIM DOWN THE LENGTH
  if (flowTicks.length > 17) flowTicks = flowTicks.filter((tick, i) => i % 3 === 0);
  else if (flowTicks.length > 12) flowTicks = flowTicks.filter((tick, i) => i % 2 === 0);

  // CREATE TIME TICKS
  let timeTicks = [];
  for (let i = 0; i <= Math.ceil(volumeTimePoints[volumeTimePoints.length - 1].timestamp) + 1; i += 1) {
    timeTicks.push(i);
  };
  // TRIM DOWN THE LENGTH
  if (timeTicks.length > 12) timeTicks = timeTicks.filter(tick => tick % 2 === 0);


  if (convertToXY) {
    const xyExhale = exhalePoints.map(point => ({ x: point.volume, y: point.flow }));
    const xyInhale = inhalePoints.map(point => ({ x: point.volume, y: point.flow }));
    const xyTime = volumeTimePoints.map(point => ({ x: point.timestamp, y: point.volume }))
    return {
      xyFlowVolume: [...xyExhale, ...xyInhale],
      xyVolumeTime: xyTime,
      fvc: spirometryResult.fvc,
      ranges: {
        volumeRange: [Math.ceil(minVol === 0 ? minVol : minVol / 1000) - 1, Math.floor(maxVol / 1000) + 2],
        flowRange: [Math.ceil(minFlow === 0) ? -6 : Math.ceil(minFlow / 1000) - 2, Math.floor(maxFlow / 100) + 3],
        timeRange: [0, Math.ceil(volumeTimePoints[volumeTimePoints.length - 1].timestamp)]
      },
      fvcStepSize: {
        xStepSize: Math.abs(volTicks[1] - volTicks[0]),
        yStepSize: Math.abs(flowTicks[1] - flowTicks[0])
      },
      vtcStepSize: {
        xStepSize: Math.abs(timeTicks[1] - timeTicks[0]),
        yStepSize: Math.abs(volTicks[1] - volTicks[0])
      }
    }
  };

  return {
    exhalePoints,
    inhalePoints,
    volumeTimePoints,
    fvc: spirometryResult.fvc,
    ranges: {
      volumeRange: [Math.ceil(minVol === 0 ? minVol : minVol / 1000) - 1, Math.floor(maxVol / 1000) + 2],
      flowRange: [Math.ceil(minFlow === 0) ? -6 : Math.ceil(minFlow / 1000) - 2, Math.floor(maxFlow / 100) + 3],
      timeRange: [0, Math.ceil(volumeTimePoints[volumeTimePoints.length - 1].timestamp)]
    },
    fvcTicks: {
      xTicks: volTicks,
      yTicks: flowTicks
    },
    vtcTicks: {
      xTicks: timeTicks,
      yTicks: volTicks
    }
  }
}

export function tidyMultiFlowVolume(spirometryResult, index) {
  if (!spirometryResult || !(spirometryResult.flowVolumeCurve)) return [];
  const bytes = Buffer.from(spirometryResult.flowVolumeCurve, 'base64');
  const text = bytes.toString('ascii');
  let json;
  try {
    json = JSON.parse(text);
  } catch (e) {
    console.log(e);
    return [];
  }
  if (!(json && json.points && json.points.length > 0 && json.points[0].timestamp)) {
    return [];
  }
  const firstTimestamp = json.points[0].timestamp;
  const exhalePoints = [{ [`flow-${index}`]: 0, volume: 0, timestamp: 0 }], inhalePoints = [], volumeTimePoints = [{ [`volume-${index}`]: 0, timestamp: 0 }];
  // const flowPoints = [];
  let minVol = 0, maxVol = -Infinity;
  let minFlow = 0, maxFlow = -Infinity;
  // if (spirometryResult.pef && ((point.flow / 100) > (spirometryResult.pef + 0.1))) point.flow = point.flow / 10;

  json.points.forEach((point, i) => {
    if (point.flow > 10000 || point.volume > 100000) console.log("error in point data - please check this curve in DB")
    else {
      if (point.flow > 0 && point.volume >= exhalePoints[exhalePoints.length - 1].volume) {
        if (inhalePoints.length === 0) {
          if (spirometryResult.pef && ((point.flow / 100) > (spirometryResult.pef + 0.1))) point.flow = point.flow / 10;
          if (point.flow > maxFlow) maxFlow = point.flow;
          if (point.volume > maxVol) maxVol = point.volume;
          exhalePoints.push({ [`flow-${index}`]: (point.flow / 100), volume: (point.volume / 1000), timestamp: point.timestamp - firstTimestamp });
        }
      }
      else if (point.flow < 0) {
        if (inhalePoints.length === 0 || (point.volume / 1000) <= inhalePoints[inhalePoints.length - 1].volume) {
          if (point.flow < minFlow) minFlow = point.flow;
          if (point.volume < minVol) minVol = point.volume;
          inhalePoints.push({ [`flow-${index}`]: (point.flow / 1000), volume: (point.volume / 1000), timestamp: point.timestamp - firstTimestamp });
        }
      }
      if (point.volume >= volumeTimePoints[volumeTimePoints.length - 1][`volume-${index}`] * 1000) {
        volumeTimePoints.push({ [`volume-${index}`]: point.volume / 1000, timestamp: (point.timestamp - firstTimestamp) / 1000 });
      }
    }
  });

  inhalePoints.unshift({ [`flow-${index}`]: 0, volume: exhalePoints[exhalePoints.length - 1].volume, timestamp: exhalePoints[exhalePoints.length - 1].timestamp + 2 })

  inhalePoints.push({ [`flow-${index}`]: 0, volume: inhalePoints[inhalePoints.length - 1].volume, timestamp: inhalePoints[inhalePoints.length - 1].timestamp + 1 })

  return {
    exhalePoints,
    inhalePoints,
    volumeTimePoints,
    ranges: {
      volumeRange: [Math.ceil(minVol === 0 ? minVol : minVol / 1000) - 1, Math.floor(maxVol / 1000) + 2],
      flowRange: [Math.ceil(minFlow === 0) ? -6 : Math.ceil(minFlow / 1000) - 2, Math.floor(maxFlow / 100) + 3],
      timeRange: [0, Math.ceil(volumeTimePoints[volumeTimePoints.length - 1].timestamp)]
    },
  }
}

export function getCurveBounds(curve) {
  let volTicks = [];
  let minVol = 0, maxVol = -Infinity;
  let minFlow = 0, maxFlow = -Infinity;

  curve.forEach(point => {
    if (point.volume < minVol) minVol = point.volume;
    if (point.volume > maxVol) maxVol = point.volume;
    if (point.flow < minFlow) minFlow = point.flow;
    if (point.flow > maxFlow) maxFlow = point.flow;
  })

  for (let i = Math.ceil(minVol === 0 ? minVol : minVol / 1000) - 1; i <= Math.floor(maxVol) + 2; i += 1) {
    volTicks.push(i);
  }

  if (volTicks.length > 12) volTicks = volTicks.filter(tick => tick % 2 === 0)

  let flowTicks = [];

  for (let i = Math.ceil(minFlow) - 2; i <= Math.floor(maxFlow) + 3; i += 1) {
    if (i % 2 === 0) flowTicks.push(i);
  }

  return {
    fvcTicks: {
      xTicks: volTicks,
      yTicks: flowTicks
    },
    ranges: {
      volumeRange: [Math.ceil(minVol === 0 ? minVol : minVol / 1000) - 1, Math.floor(maxVol) + 2],
      flowRange: [Math.ceil(minFlow === 0) ? -6 : Math.ceil(minFlow) - 2, Math.floor(maxFlow) + 3],
    }
  }
}

export function tidySvcVolumeTime(spirometryResult, convertToXY = false) {
  if (!spirometryResult || !(spirometryResult.flowVolumeCurve)) return [];
  const containsVtPointsCurve = !!spirometryResult?.vtPointsCurve;
  // const bytes = containsVtPointsCurve ? Buffer.from(spirometryResult.vtPointsCurve, 'base64') : Buffer.from(spirometryResult.flowVolumeCurve, 'base64');
  // const text = bytes.toString('ascii');
  const decodedCurve = containsVtPointsCurve ? atob(spirometryResult.vtPointsCurve) : atob(spirometryResult.flowVolumeCurve);
  let json, corrupted = false;
  try {
    // json = JSON.parse(text);
    json = JSON.parse(decodedCurve);
  } catch (e) {
    console.log(e);
    return [];
  }
  if (json?.points?.length === 0) return [];
  if (containsVtPointsCurve) { // Copy the MIR data to our conventionally named properties
    json.points.forEach(point => {
      point.volume = point.volume_L
      point.timestamp = point.time_s
    });
  }

  // This is done to remove all the 0's before breath is detected at the start
  if (json.points[0].volume === json.points[1].volume) {
    while (json.points[0].volume === json.points[1].volume) json.points.shift();
    json.points.shift();
  }
  let firstTimestamp = json.points[0].timestamp;
  let minVol = Infinity, maxVol = -Infinity, maxVolTime = 0;
  let points = [];
  json.points.forEach((point, i) => {
    if (point.volume > 100000) {
      console.log("error in point data - please check this curve in DB");
      corrupted = true;
    } else {
      if (i === 0 || (i > 0 && point.volume !== points[points.length - 1].volume)) {
        if (point.volume > maxVol) {
          maxVol = point.volume;
          maxVolTime = containsVtPointsCurve ? point.timestamp - firstTimestamp : (point.timestamp - firstTimestamp) / 1000;
        }
        if (point.volume < minVol) minVol = point.volume;
        points.push({
          volume: containsVtPointsCurve ? point.volume : point.volume / 1000,
          time: containsVtPointsCurve ? (point.timestamp - firstTimestamp) : (point.timestamp - firstTimestamp) / 1000
        })
      }
    }
  });

  // We need to transpose the curve points so the curve volume lives above and eventually ends at zero (lowest point)
  const transposition = containsVtPointsCurve ? minVol * -1 : (minVol / 1000) * -1;

  points.forEach(point => {
    point.volume = point.volume + transposition;
    point.time = point.time - maxVolTime;
  });

  maxVol = containsVtPointsCurve ? maxVol + transposition : (maxVol / 1000) + transposition;

  let timeTicks = [];
  for (let i = Math.ceil(points[0].time) - 1; i <= Math.ceil(points[points.length - 1].time) + 2; i += 1) timeTicks.push(i);
  if (timeTicks.length > 36) timeTicks = timeTicks.filter(tick => tick % 4 === 0);
  else if (timeTicks.length > 24) timeTicks = timeTicks.filter(tick => tick % 3 === 0);
  else if (timeTicks.length > 12) timeTicks = timeTicks.filter(tick => tick % 2 === 0);

  let volTicks = [];
  for (let i = -1; i <= Math.ceil(maxVol) + 1; i += 1) volTicks.push(i);
  if (volTicks.length > 12) volTicks = volTicks.filter(tick => tick % 2 === 0);

  if (convertToXY) {
    const xyPoints = points.map(point => ({ x: point.time, y: point.volume }));
    return {
      xyVolumeTime: xyPoints,
      ranges: {
        timeRange: [Math.ceil(points[0].time) - 1, Math.ceil(points[points.length - 1].time) + 2],
        volumeRange: [-1, Math.ceil(maxVol) + 1],
      },
      vcStepSize: {
        xStepSize: Math.abs(timeTicks[1] - timeTicks[0]),
        yStepSize: Math.abs(volTicks[1] - volTicks[0])
      }
    }
  }

  return ({
    points,
    svcTicks: {
      xTicks: timeTicks,
      yTicks: volTicks
    },
    ranges: {
      timeRange: [Math.ceil(points[0].time) - 1, Math.ceil(points[points.length - 1].time) + 2],
      volumeRange: [-1, Math.ceil(maxVol) + 1],
    },
    corrupted
  })
}

export function tidyMultiSvcVolumeTime(spirometryResult, index) {
  if (!spirometryResult || !(spirometryResult.flowVolumeCurve)) return [];
  const bytes = Buffer.from(spirometryResult.flowVolumeCurve, 'base64');
  const text = bytes.toString('ascii');
  let json;
  try {
    json = JSON.parse(text);
  } catch (e) {
    console.log(e);
    return [];
  }
  if (!(json && json.points && json.points.length > 0 && json.points[0].timestamp)) {
    return [];
  }
  let firstTimestamp = 0
  for (let i = 0; i < json.points.length; i += 1) {
    if (json.points[i].flow === 0) {
      firstTimestamp = json.points[i].timestamp;
    }
    else {
      break;
    }
  }
  let maxVol = -Infinity, maxVolTime = 0;
  let points = [];
  json.points.forEach((point, i) => {
    if (json.points[i].flow > 10000 || json.points[i].volume > 100000) console.log("error in point data - please check this curve in DB")
    else {
      if (point.flow !== 0) {
        if (point.volume > maxVol) {
          maxVol = point.volume;
          maxVolTime = (point.timestamp - firstTimestamp) / 1000;
        }
        points.push({ [`volume-${index}`]: point.volume / 1000, time: (point.timestamp - firstTimestamp) / 1000 })
      }
    }
  });

  const transposeVol = json.points[json.points.length - 1].volume;
  const transposition = (transposeVol / 1000) * -1;

  points.forEach(point => {
    point[`volume-${index}`] = point[`volume-${index}`] + transposition;
    point.time = point.time - maxVolTime;
  });

  return ({
    points,
    ranges: {
      timeRange: [Math.ceil(points[0].time) - 1, Math.ceil(points[points.length - 1].time) + 2],
      volumeRange: [-1, Math.ceil(maxVol / 1000) + 2],
    }
  })
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(name, value, minutes) {
  const d = new Date();
  d.setTime(d.getTime() + (minutes * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function removeCookie(name) {
  let expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
  document.cookie = `${name}=;${expires};path=/`;
}

// input an orgID and return the UUID portion
// e.x) '{orgID}_training' => '{orgID}
export function trimOrganizationID(orgID) {
  return orgID.substring(0, 36);
};

// inputs: 'viewingAs' object, 'detailedOrganizationsForProvider' list of detailed organizations
export function getCurrentOrganization(viewingAs, detailedOrganizationsForProvider) {
  if (!(viewingAs && viewingAs.organizationID)) {
    throw new Error('InvalidParameters', `updateOrganizationForTrainingMode error: invalid viewingAs: ${JSON.stringify(viewingAs)}`);
  }
  if (!detailedOrganizationsForProvider) {
    console.log(`updateOrganizationForTrainingMode warning: invalid or empty detailedOrganizationsForProvider`);
    detailedOrganizationsForProvider = {};
  }
  const actualOrgID = viewingAs.organizationID;
  const primaryOrgID = trimOrganizationID(viewingAs.organizationID);
  const primaryOrg = detailedOrganizationsForProvider[primaryOrgID];
  if (!primaryOrg) {
    return undefined;
  }
  const isTrainingMode = actualOrgID.endsWith('_training');
  if (isTrainingMode) {
    return {
      ...primaryOrg,
      name: `${primaryOrg.name} Training`,
      organizationID: `${primaryOrg.organizationID}_training`
    };
  }
  return primaryOrg;
};

export function sortRunInAvgEntries(entries) {
  const typeOrder = ['compliance', 'rescueMedLast7', 'rescueMedPrevious7', 'rescueMedTotal', 'pefVariabilityAverage', 'pefAMAverage', 'pefPMAverage']; // Run-in Calculation Table Order

  return entries.sort((a, b) => {
    return typeOrder.indexOf(a[0]) - typeOrder.indexOf(b[0]);
  });
};